import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="contact-feedback" fill="CurrentColor" fillRule="nonzero">
        <path
          d="M30,60 C11.777,60 0,48.223 0,30 C0,11.777 11.777,0 30,0 C48.223,0 60,11.778 60,30 C60,48.222 48.223,60 30,60 Z M21.25,18.75 C18.972,18.75 17.5,20.713 17.5,23.75 C17.5,26.787 18.972,28.75 21.25,28.75 C23.528,28.75 25,26.787 25,23.75 C25,20.713 23.528,18.75 21.25,18.75 Z M38.75,18.75 C36.472,18.75 35,20.713 35,23.75 C35,26.787 36.472,28.75 38.75,28.75 C41.028,28.75 42.5,26.787 42.5,23.75 C42.5,20.713 41.028,18.75 38.75,18.75 Z M42.528,36.25 L17.472,36.25 C17.0701382,36.2515957 16.6944846,36.4497184 16.4662161,36.7804584 C16.2379477,37.1111984 16.1859514,37.5327013 16.327,37.909 C18.2503466,43.7866886 23.8186251,47.6925878 30,47.5 C36.1811049,47.6927233 41.7492966,43.7872923 43.673,37.91 C43.814465,37.5335855 43.762675,37.1117585 43.5343551,36.7807445 C43.3060352,36.4497306 42.9301169,36.2514739 42.528,36.25 L42.528,36.25 Z"
          id="Emoji"
        ></path>
      </g>
    </g>
  </svg>
))
