import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 48 40"
    width="48"
    height="40"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      id="Mail"
      className="cls-1"
      d="M0,32.005V7.991a8,8,0,0,1,8-8H40a8,8,0,0,1,8,8V32.005a8,8,0,0,1-8,8H8A8,8,0,0,1,0,32.005Zm42,0V17.2L27.328,26.988a6,6,0,0,1-6.656,0L6,17.2v14.8a2,2,0,0,0,2,2H40A2,2,0,0,0,42,32.005ZM8,5.99a2,2,0,0,0-2,2V8.378H6a3,3,0,0,0,1.337,2.5L24,21.994,40.663,10.878A3,3,0,0,0,42,8.378h0V7.991a2,2,0,0,0-2-2H8Z"
    />
  </svg>
))
