import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 48 48"
    width="48"
    height="48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      id="Search"
      className="cls-1"
      d="M47.541,43.294a1.5,1.5,0,0,1,0,2.123l-2.123,2.123a1.5,1.5,0,0,1-2.123,0L33.072,37.316a1.481,1.481,0,0,0-1.81-.234,21.51,21.51,0,0,1-11.27,2.905c-12.146,0-20-7.85-20-20s7.85-20,20-20,20,7.85,20,20a21.508,21.508,0,0,1-2.905,11.27,1.481,1.481,0,0,0,.234,1.81ZM19.993,5.994c-8.9,0-14,5.1-14,14s5.1,14,14,14,14-5.1,14-14S28.888,5.994,19.993,5.994Z"
    />
  </svg>
))
