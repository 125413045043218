import { graphql } from "gatsby"
import React, { FC } from "react"
import { Layout } from "src/components/Layout"
import { Props } from "./types"
import * as styles from "./styles.module.scss"
import CardLink from "src/components/CardLink"
import Email from "src/components/Icons/Email"
import Search from "src/components/Icons/Search"
import Share from "src/components/Icons/Share"
import { usePages } from "src/context/pages"
import Logo from "src/images/structured-data-logo.png"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
  },
}) => {
  const { getPage, currentPath } = usePages()

  return (
    <>
      <Layout
        title={texts.metaTitle}
        description={texts.metaDescription}
        openGraph={{
          title: texts.metaTitle,
          description: texts.metaDescription,
          url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
          images: [
            {
              url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
              width: 800,
              height: 600,
              alt: "Flow logo",
            },
            {
              url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
              width: 900,
              height: 800,
              alt: "Flow logo",
            },
          ],
        }}
        className={styles.layout}
      >
        <div className={`content-wrapper ${styles.content}`}>
          <h1 className={styles.title}>{texts.header}</h1>
          <p className={`${styles.subtitle} ${styles.subtitleBold}`}>
            {texts.subheader}
          </p>
          <p className={styles.subtitle}>{texts.text}</p>
          <div className={styles.apps}>
            <CardLink
              url="mailto:help@getflow.com"
              title={texts.email.title}
              subtitle={texts.email.subtitle}
            >
              <Email />
            </CardLink>
            <CardLink
              url={getPage("help-center").url}
              title={texts.helpCenter.title}
              subtitle={texts.helpCenter.subtitle}
            >
              <Search className={styles.search} />
            </CardLink>
            <CardLink
              url={getPage("feedback").url}
              title={texts.share.title}
              subtitle={texts.share.subtitle}
            >
              <Share className={styles.share} />
            </CardLink>
          </div>
        </div>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        metaTitle
        metaDescription
        header
        subheader
        text
        email {
          title
          subtitle
        }
        helpCenter {
          title
          subtitle
        }
        share {
          title
          subtitle
        }
        info
      }
    }
  }
`

export default Template
