import React, { FC } from "react"
import { Props } from "./types"
import Link from "src/utils/Link"
import * as styles from "./styles.module.scss"

const CardLink: FC<Props> = ({
  subtitle,
  title,
  url,
  children,
  bigCard = false,
  className,
  onClick,
}) => {
  return (
    <Link to={url} target="_blank" onClick={onClick}>
      <div
        className={`${styles.app} ${className} ${
          bigCard ? styles.bigCard : ""
        }`}
      >
        <div
          className={`${styles.iconContent} ${bigCard ? styles.bigIcon : ""}`}
        >
          {children}
        </div>
        <h3 className={styles.appTitle}>{title}</h3>
        <p
          className={`${styles.appSubtitle} ${
            !bigCard ? styles.appSubtitle_dark : ""
          }`}
        >
          {subtitle}
        </p>
      </div>
    </Link>
  )
}

export default CardLink
